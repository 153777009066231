import React from 'react';
import {
  Typography,
  Button,
  IconButton,
  Box,
  Paper,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useSelector } from 'react-redux';
import { userLSAuth } from '../app/features/env';
import { messageSelector } from '../utils/selectors/selectors';
import { Link } from 'react-router-dom';

const InsufficientSmsDialog = ({ resetInsufficient}) => {
  const selector = useSelector(messageSelector);

  const handleClose = () => {
    console.log('Component dismissed');
  };

 

  return (
    <Paper elevation={3} sx={{ padding: 3, maxWidth: '500px', margin: ' auto' }}>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Box display="flex" alignItems="center">
          <WarningAmberIcon color="warning" sx={{ marginRight: 1 }} />
          <Typography variant="h6" id="insufficient-sms-title" sx={{ fontWeight: 600, fontSize: '1.4rem', color: '#333333' }}>
            Insufficient SMS Units
          </Typography>
        </Box>
        <IconButton onClick={() => resetInsufficient()} size="small">
          <CloseIcon />
        </IconButton>
      </Box>
      <Typography sx={{ fontWeight: 500, fontSize: '1.1rem', color: '#333333' }}>
        It looks like your balance is too low to send this message. Please top
        up your SMS units to continue.
      </Typography>
      <Box mt={2} >
        <Typography sx={{ fontWeight: 500, fontSize: '1.1rem', color: '#333333' }}>
          <strong>Required units: {`${selector.msgCost ? selector.msgCost.totalCost : 0}`}</strong>
        </Typography>
        <Typography sx={{ fontWeight: 500, fontSize: '1.1rem', color: '#333333' }}>
          <strong>Available units:{' '}
          {userLSAuth().balance == null ? '0' : userLSAuth().balance}</strong>
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={3}>
        <Button  onClick={() => resetInsufficient()} variant="outlined" sx={{ marginRight: 1,borderColor: '#E3E3E3', color: '#333333' ,paddingX: 3}}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" >
          <Link to="/top-up" style={{ textDecoration: 'none', color: 'inherit' }}>
          Top Up Now
          </Link>
          
        </Button>
      </Box>
    </Paper>
  );
};

export default InsufficientSmsDialog;
