import {
   getBaseUrls,
   userLSAuth
} from '../env.js';
import httpclient from '../httpClient.js';
import {
   toast
} from 'react-toastify';
import {
   createAsyncThunk,
   createSlice
} from '@reduxjs/toolkit';
import setAuthToken from '../../../utils/setAuthToken.js';

const initialState = {
   messages: [],
   message: [],
   selectedContacts: [],
   selectedGroups: [],
   selectedNumbers: [],
   loading: 'idle',
   senderIds: [],
   selectedSenderId: '',
   error: null,
   sent: false,
   sending: false,
   created: false,
   scheduled: false,
   deleted: false,
   scheduledMessages: [],
   draftedMessages: [],
   archivedMessages: [],
   msgCost: null,
   hasMore: true,
   paginate: 0,
   searchedMessages: [],
   content: '',
   senderId: '',
   scheduledAt: null,
   contactids: [],
   groupids: [],
   numbers: [],
   selectedContent:'',
   location:''
};

const handleGroupFilter = (newArr, oldArr) => {
   if (!oldArr.length) return newArr;

   const ans = [...oldArr]; // Start with a copy of oldArr
   const existingIds = new Set(oldArr.map(item => item.id)); // Create a Set of existing IDs

   newArr.forEach(item => {
      if (!existingIds.has(item.id)) {
         ans.push(item); // Add item if its ID isn't in the Set
      }
   });

   return ans;
};


const handleGroupRemove = (oldArr, id) => {
   return oldArr.filter(item => item.id !== id);
};

const handleContactRemove = (oldArr, number) => {
   return oldArr.filter(item =>
      (typeof item === 'string' && item !== number) ||
      (typeof item === 'object' && item.number !== number)
   );
};

const handleContactFilter = (newArr, oldArr) => {
   if (!oldArr.length) return newArr;

   const ans = [...oldArr]; // Start with a copy of oldArr
   const existingIds = new Set(oldArr.map(item => item.id)); // Create a Set of existing IDs

   newArr.forEach(item => {
      if (!existingIds.has(item.id)) {
         ans.push(item); // Add item if its ID isn't in the Set
      }
   });

   return ans;
};


export const fetchsenderIDs = createAsyncThunk(
   'get/senderIds',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);

      try {
         const response = await httpclient.get(
            `${getBaseUrls().data}/v1/message-senders/${userLSAuth().id}`
         );

         if (response.status && response.status === 200) {
            // toast.success(response.data.message);
            return response.data;
         }

         return rejectWithValue(response.data.data);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

export const sendExistingMessage = createAsyncThunk(
   'sendExisting/message',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         };
         const response = await httpclient.post(
            `${getBaseUrls().data}/v1/message/${
               userLSAuth().id
            }/send-existing/${payload.id}`,
            payload.msg,
            config
         );

         if (
            response.data.code === 200 ||
            response.data.code === 201 ||
            response.data.code === 204
         ) {
            // toast.success(response.data.message);
            return response.data;
         }

         return rejectWithValue(response);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

export const sendmessage = createAsyncThunk(
   'send/message',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         };
         const response = await httpclient.post(
            `${getBaseUrls().data}/v1/message/${userLSAuth().id}/send`,
            payload,
            config
         );

         if (
            response.data.code === 200 ||
            response.data.code === 201 ||
            response.data.code === 204
         ) {
            toast.success(response.data.message);
            return response.data;
         }

         return rejectWithValue(response);
      } catch (error) {
         console.log(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

export const schedulemessage = createAsyncThunk(
   'schedule/message',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         };
         const response = await httpclient.post(
            `${getBaseUrls().data}/v1/message/${userLSAuth().id}/schedule`,
            payload,
            config
         );

         if (
            response.data.code === 200 ||
            response.data.code === 201 ||
            response.data.code === 204
         ) {
            // toast.success(response.data.message);
            return response.data;
         }

         return rejectWithValue(response);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

export const draftmessage = createAsyncThunk(
   'draft/message',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         };
         const response = await httpclient.post(
            `${getBaseUrls().data}/v1/message/${userLSAuth().id}/draft`,
            payload,
            config
         );

         if (
            response.data.code === 200 ||
            response.data.code === 201 ||
            response.data.code === 204
         ) {
            // toast.success(response.data.message);
            return response.data;
         }

         return rejectWithValue(response);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

export const editExistingMessage = createAsyncThunk(
   'edit/message',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         };
         const response = await httpclient.put(
            `${getBaseUrls().data}/v1/message/${userLSAuth().id}/edit/${
               payload.id
            }`,
            payload,
            config
         );

         if (
            response.data.code === 200 ||
            response.data.code === 201 ||
            response.data.code === 204
         ) {
            toast.success(response.data.message);
            return response.data;
         }

         return rejectWithValue(response);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

export const getUserMessages = createAsyncThunk(
   'get/messages',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);

      try {
         const response = await httpclient.get(
            `${getBaseUrls().data}/v1/message/${userLSAuth().id}?page=${
               payload.page
            }&take=50&state=history`
         );

         if (response.status && response.status === 200) {
            return response.data;
         }

         return rejectWithValue(response.data.data);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

export const getScheduledMessages = createAsyncThunk(
   'get/scheduledMessages',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);

      try {
         const response = await httpclient.get(
            `${getBaseUrls().data}/v1/message/${userLSAuth().id}?page=${
               payload.page
            }&take=50&state=schedule`
         );
         if (response.status && response.status === 200) {
            return response.data;
         }

         return rejectWithValue(response.data.data);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

export const getDraftedMessages = createAsyncThunk(
   'get/draft',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);

      try {
         const response = await httpclient.get(
            `${getBaseUrls().data}/v1/message/${userLSAuth().id}?page=${
               payload.page
            }&take=50&state=draft`
         );
         if (response.status && response.status === 200) {
            return response.data;
         }
         return rejectWithValue(response.data.data);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);
export const getArchivedMessages = createAsyncThunk(
   'get/archives',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);

      try {
         const response = await httpclient.get(
            `${getBaseUrls().data}/v1/message/${userLSAuth().id}?page=${
               payload.page
            }&take=50&state=archive`
         );
         if (response.status && response.status === 200) {
            return response.data;
         }
         return rejectWithValue(response.data.data);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

export const getSingleMessage = createAsyncThunk(
   'get/message',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);

      try {
         const response = await httpclient.get(
            `${getBaseUrls().data}/v1/message/${userLSAuth().id}/${payload.id}`
         );
         if (response.status && response.status === 200) {
            return response.data;
         }

         return rejectWithValue(response.data.data);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

export const archiveMessages = createAsyncThunk(
   'archive/message',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         };
         const response = await httpclient.patch(
            `${getBaseUrls().data}/v1/message/${userLSAuth().id}/archive`,
            payload,
            config
         );
         toast.success('Message archived successfully');
         return rejectWithValue(response);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

export const deleteMessage = createAsyncThunk(
   'delete/message',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);

      try {
         const response = await httpclient.delete(
            `${getBaseUrls().data}/v1/message/${
               userLSAuth().id
            }/delete-multiple?ids=${payload.ids}`
         );

         if (response.status && response.status === 200) {
            toast.success(response.data.message);
            return response.data;
         }

         return rejectWithValue(response.data.data);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);
export const searchMessage = createAsyncThunk(
   'search/message',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);

      try {
         const response = await httpclient.get(
            `${getBaseUrls().data}/v1/search-message/${
               userLSAuth().id
            }?searchTerm=${payload.searchTerm}`
         );

         if (response.status && response.status === 200) {
            // toast.success(response.data);
            return response.data;
         }

         return rejectWithValue(response.data.data);
      } catch (error) {
         error.response.data.message !== 'Invalid search term' &&
            toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

export const suggestFeature = createAsyncThunk(
   'suggest/feature',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         };
         const response = await httpclient.post(
            `${getBaseUrls().data}/v1/feature`,
            payload,
            config
         );
         if (
            response.data.code === 200 ||
            response.data.code === 201 ||
            response.data.code === 204
         ) {
            // toast.success(response.data.message);
            return response.data;
         }
         return rejectWithValue(response);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

export const createSenderId = createAsyncThunk(
   'create/senderId',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         };
         const response = await httpclient.post(
            `${getBaseUrls().external}/api/v1/sms/sender-id`,
            payload,
            config
         );

         if (
            response.data.code === 200 ||
            response.data.code === 201 ||
            response.data.code === 204
         ) {
            return response.data;
         }

         return rejectWithValue(response);
      } catch (error) {
         toast.error(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);


export const Apikey = createAsyncThunk(
   'create/apikey',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         };
         const response = await httpclient.post(
            `${getBaseUrls().data}/v1/external`,
            payload,
            config
         );

         if (
            response.data.code === 200 ||
            response.data.code === 201 ||
            response.data.code === 204
         ) {
            return response.data;
         }

         return rejectWithValue(response);
      }catch (error) {
         toast.error(error.response?.data?.message || "An error occurred");
         return rejectWithValue({
             message: error.response?.data?.message || error.message,
             status: error.response?.status || 500,
         });
     }
     
   }
);
export const DisableApikey = createAsyncThunk(
   'disable/apikey',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         };
         const response = await httpclient.patch(
            `${getBaseUrls().data}/v1/external/${userLSAuth().id}`,
            payload,
            config
         );

         if (
            response.data.code === 200 ||
            response.data.code === 201 ||
            response.data.code === 204
         ) {
            return response.data;
         }

         return rejectWithValue(response);
      }catch (error) {
         toast.error(error.response?.data?.message || "An error occurred");
         return rejectWithValue({
             message: error.response?.data?.message || error.message,
             status: error.response?.status || 500,
         });
     }
     
   }
);

export const getApiKey = createAsyncThunk(
   'get/ApiKey',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         };
         const response = await httpclient.get(
            `${getBaseUrls().data}/v1/external/${userLSAuth().id}`,
          
         );

         if (
            response.data.code === 200 ||
            response.data.code === 201 ||
            response.data.code === 204
         ) {
            return response.data.data;
         }

         return rejectWithValue(response);
      } catch (error) {
         console.log(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);



export const getMsgCost = createAsyncThunk(
   'get/msgCost',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         };
         const response = await httpclient.post(
            `${getBaseUrls().data}/v1/message-price/${userLSAuth().id}`,
            payload,
            config
         );

         if (
            response.data.code === 200 ||
            response.data.code === 201 ||
            response.data.code === 204
         ) {
            return response.data.data;
         }

         return rejectWithValue(response);
      } catch (error) {
         console.log(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

export const selectSenderId = createAsyncThunk(
   'set/selectSenderId',
   (payload, _) => {
      return payload.senderId;
   }
);

export const smsCost = createAsyncThunk(
   'get/smsCost',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         };
         const response = await httpclient.get(
            `${getBaseUrls().external}/api/v1/calculator/sms-cost/?dndContacts=${payload.dndContacts}&nonDndContacts=${payload.nonDndContacts}&messagePage=${payload.messagePage }`,
            payload,
          
         );

         if (
            response.data.code === 200 ||
            response.data.code === 201 ||
            response.data.code === 204
         ) {
            return response.data.data;
         }

         return rejectWithValue(response);
      } catch (error) {
         console.log(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

export const topupWithUnit = createAsyncThunk(
   'get/topupWithUnit',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         };
         const response = await httpclient.get(
            `${getBaseUrls().external}/api/v1/calculator/topup-with-unit?unit=${payload}`,
            payload,
           
         );

         if (
            response.data.code === 200 ||
            response.data.code === 201 ||
            response.data.code === 204
         ) {
            return response.data.data;
         }

         return rejectWithValue(response);
      } catch (error) {
         console.log(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

export const topupWithNaira = createAsyncThunk(
   'get/topupWithNaira',
   async (payload, {
      rejectWithValue
   }) => {
      const token = localStorage.getItem('accessToken');
      setAuthToken(token);
      try {
         const config = {
            headers: {
               'Content-Type': 'application/json',
            },
         };
         const response = await httpclient.get(
            `${getBaseUrls().external}/api/v1/calculator/topup-with-amount?amount=${payload}`,
            payload,
           
         );

         if (
            response.data.code === 200 ||
            response.data.code === 201 ||
            response.data.code === 204
         ) {
            return response.data.data;
         }

         return rejectWithValue(response);
      } catch (error) {
         console.log(error.response.data.message);
         return rejectWithValue(error);
      }
   }
);

const messageSlice = createSlice({
   name: 'message',
   initialState,
   reducers: {
      addSelectedNumbers: (state, action) => {
         const newItems = action.payload;
         state.selectedNumbers = [...state.selectedNumbers, ...newItems];
      },
      
      addSelectedGroups: (state, {
         payload: newItem
      }) => {
         state.selectedGroups = handleGroupFilter(newItem.slice(), state.selectedGroups.slice());
      },

      addSelectedContent(state, action) {
         state.content = action.payload; 
      },
      updateSelectedContent: (state, action) => {
         state.selectedContent = action.payload;
      },

      updateLocation: (state, action) => {
         state.location = action.payload
      },

      clearSelectedContent(state) {
         state.selectedContent = ''
       },

      removeSelectedGroups: (state, {
         payload: item
      }) => {
         state.selectedGroups = handleGroupRemove(state.selectedGroups.slice(), item);
      },


      addSelectedContacts: (state, action) => {
         const newItem = action.payload;
         state.selectedContacts = handleContactFilter(newItem, state.selectedContacts);
      },
      removeSelectedContacts: (state, {
         payload: number
      }) => {
         state.selectedContacts = handleContactRemove(state.selectedContacts.slice(), number);
         state.selectedNumbers = handleContactRemove(state.selectedNumbers.slice(), number);
      },

      resetCreated: (state, action) => {
         state.selectedContacts = [];
         state.selectedGroups = [];
         state.selectedNumbers = [];
         state.sent = false;
         state.sending = false;
         state.scheduled = false;
         state.loading = 'idle';
      },
      falsifyCreated: (state, action) => {
         state.created = false;
      },
      resetSelectedContacts: (state, action) => {
         state.selectedContacts = [];
         state.selectedGroups = [];
         state.selectedNumbers = [];
      },
      resetSelectedId: (state, action) => {
         state.selectedSenderId = ''
        
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(fetchsenderIDs.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(fetchsenderIDs.fulfilled, (state, action) => {
            state.loading = 'success';
            state.senderIds = action.payload.data;
         })
         .addCase(fetchsenderIDs.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(selectSenderId.fulfilled, (state, action) => {
            state.selectedSenderId = action.payload;
         })
         .addCase(sendmessage.pending, (state, action) => {
            
            state.loading = 'idle';
            state.sending = true;
         })
         .addCase(sendmessage.fulfilled, (state, action) => {
            state.loading = 'success';
            state.sent = true;
            state.sending = false;
            state.selectedContacts = [];
            state.selectedGroups = [];
            state.scheduled = false;
            state.loading = 'idle';
            state.selectedSenderId = '';
         })
         .addCase(sendmessage.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
             state.sending = false;
         })
         .addCase(sendExistingMessage.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(sendExistingMessage.fulfilled, (state, action) => {
            state.loading = 'success';
            state.sent = true;
         })
         .addCase(sendExistingMessage.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(schedulemessage.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(schedulemessage.fulfilled, (state, action) => {
            state.loading = 'success';
            state.scheduled = true;
         })
         .addCase(schedulemessage.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(draftmessage.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(draftmessage.fulfilled, (state, action) => {
            state.loading = 'success';
         })
         .addCase(draftmessage.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(editExistingMessage.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(editExistingMessage.fulfilled, (state, action) => {
            state.loading = 'success';
         })
         .addCase(editExistingMessage.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(archiveMessages.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(archiveMessages.fulfilled, (state, action) => {
            state.loading = 'success';
         })
         .addCase(archiveMessages.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(getUserMessages.pending, (state, action) => {
            state.loading = 'pending';
           
         })
         .addCase(getUserMessages.fulfilled, (state, action) => {
            state.paginate = action.payload.meta.total;
            const newData = action.payload.data;

            if (
               state.messages.length === state.paginate ||
               action.payload.data.length === 0
            ) {
               state.hasMore = false;
            } else {
               state.hasMore = true;
               // Filter out items that are already in the messages

               const filteredData = newData.filter(
                  (item) => !state.messages.some((data) => data.id === item.id)
               );
               state.messages = [...state.messages, ...filteredData];
               if (state.messages.length === state.paginate) {
                  state.hasMore = false;
               }
            }
            state.loading = 'success';
         })
         .addCase(getUserMessages.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(getDraftedMessages.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(getDraftedMessages.fulfilled, (state, action) => {
            state.paginate = action.payload.meta.total;
            const newData = action.payload.data;

            if (
               state.draftedMessages.length === state.paginate ||
               action.payload.data.length === 0
            ) {
               state.hasMore = false;
            } else {
               state.hasMore = true;
               // Filter out items that are already in the draftedMessages

               const filteredData = newData.filter(
                  (item) =>
                  !state.draftedMessages.some((data) => data.id === item.id)
               );

               state.draftedMessages = [
                  ...state.draftedMessages,
                  ...filteredData,
               ];
               if (state.draftedMessages.length === state.paginate) {
                  state.hasMore = false;
               }
            }
            state.loading = 'success';
         })
         .addCase(getDraftedMessages.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(getArchivedMessages.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(getArchivedMessages.fulfilled, (state, action) => {
            state.paginate = action.payload.meta.total;
            const newData = action.payload.data;

            if (
               state.archivedMessages.length === state.paginate ||
               action.payload.data.length === 0
            ) {
               state.hasMore = false;
            } else {
               state.hasMore = true;
               // Filter out items that are already in the draftedMessages

               const filteredData = newData.filter(
                  (item) =>
                  !state.archivedMessages.some((data) => data.id === item.id)
               );

               state.archivedMessages = [
                  ...state.archivedMessages,
                  ...filteredData,
               ];
               if (state.archivedMessages.length === state.paginate) {
                  state.hasMore = false;
               }
            }
            state.loading = 'success';
         })
         .addCase(getArchivedMessages.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(getSingleMessage.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(getSingleMessage.fulfilled, (state, action) => {
            state.loading = 'success';
            state.message = action.payload.data;
         })
         .addCase(getSingleMessage.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(getScheduledMessages.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(getScheduledMessages.fulfilled, (state, action) => {
            state.paginate = action.payload.meta.total;
            const newData = action.payload.data;

            if (
               state.scheduledMessages.length === state.paginate ||
               action.payload.data.length === 0
            ) {
               state.hasMore = false;
            } else {
               state.hasMore = true;
               // Filter out items that are already in the messages
               const filteredData = newData.filter(
                  (item) =>
                  !state.scheduledMessages.some(
                     (data) => data.id === item.id
                  )
               );
               state.scheduledMessages = [
                  ...state.scheduledMessages,
                  ...filteredData,
               ];

               if (state.scheduledMessages.length === state.paginate) {
                  state.hasMore = false;
               }
            }
            state.loading = 'success';
         })
         .addCase(getScheduledMessages.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(deleteMessage.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(deleteMessage.fulfilled, (state, action) => {
            state.loading = 'success';
            state.deleted = true;
         })
         .addCase(deleteMessage.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(suggestFeature.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(suggestFeature.fulfilled, (state, action) => {
            state.loading = 'success';
            state.created = true;
         })
         .addCase(suggestFeature.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         })
         .addCase(createSenderId.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(createSenderId.fulfilled, (state, action) => {
            state.loading = 'success';
            state.created = true;
         })
         .addCase(createSenderId.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
            toast.error(action.payload.data.message);
         })
      
         .addCase(Apikey.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(Apikey.fulfilled, (state, action) => {
            state.loading = 'success';
            state.created = true;
         })
         .addCase(Apikey.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action?.payload;
           
         })
         .addCase(DisableApikey.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(DisableApikey.fulfilled, (state, action) => {
            state.loading = 'success';
            state.created = true;
         })
         .addCase(DisableApikey.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action?.payload;
           
         })
         .addCase(getMsgCost.pending, (state, action) => {
            state.loading = 'pending';
            state.sending = false;
         })
         .addCase(getMsgCost.fulfilled, (state, action) => {
            state.loading = 'success';
            state.msgCost = action.payload;
            state.msgSummary = true;
            state.sending = false;
         })
         .addCase(getMsgCost.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
            state.sending = false;
         })
         .addCase(getApiKey.pending, (state, action) => {
            state.loading = 'pending';
            state.sending = false;
         })
         .addCase(getApiKey.fulfilled, (state, action) => {
            state.loading = 'success';
            
         })
         .addCase(getApiKey.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
           
         })
         .addCase(smsCost.pending, (state) => {
            state.loading = true;
            state.error = null;
         })
         .addCase(smsCost.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
         })
         .addCase(smsCost.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload || 'Failed to fetch SMS cost';
         })
         .addCase(topupWithUnit.pending, (state) => {
            state.loading = 'pending';
            state.error = null;
         })
         .addCase(topupWithUnit.fulfilled, (state, action) => {
            state.loading = 'success';
            state.data = action.payload;
         })
         .addCase(topupWithUnit.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload || 'Failed to fetch SMS unit';
         })
         .addCase(topupWithNaira.pending, (state) => {
            state.loading = 'pending';
            state.error = null;
         })
         .addCase(topupWithNaira.fulfilled, (state, action) => {
            state.loading =   'success';
            state.data = action.payload;
         })
         .addCase(topupWithNaira.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload || 'Failed to fetch amount';
         })
         .addCase(searchMessage.pending, (state, action) => {
            state.loading = 'pending';
         })
         .addCase(searchMessage.fulfilled, (state, action) => {
            state.paginate = action.payload.data.length;
            const newData = action.payload.data;

            if (
               state.searchedMessages.length === state.paginate ||
               action.payload.data.length === 0
            ) {
               state.hasMore = false;
            } else {
               state.hasMore = true;
               // Filter out items that are already in the messages
               // const filteredData = newData.filter(
               //    (item) =>
               //       !state.searchedMessages.some((data) => data.id === item.id)
               // );
               // state.searchedMessages = [
               //    ...state.searchedMessages,
               //    ...filteredData,
               // ];
               state.searchedMessages = [...action.payload.data];
               if (state.searchedMessages.length === state.paginate) {
                  state.hasMore = false;
               }
            }
            state.loading = 'success';
         })
         .addCase(searchMessage.rejected, (state, action) => {
            state.loading = 'failed';
            state.error = action.payload;
         });
   },
});
export const {
   addSelectedGroups,
   addSelectedNumbers,
   removeSelectedGroups,
   addSelectedContacts,
   removeSelectedContacts,
   resetCreated,
   falsifyCreated,
   resetMsgSummary,
   resetSelectedContacts,
   resetSelectedId,
   updateScheduledContent,
   addSelectedContent,
   updateSelectedContent,
   clearSelectedContent,
   updateLocation,
} = messageSlice.actions;
export default messageSlice.reducer;