import React from 'react';
import { Link } from 'react-router-dom';
import {
   Box,
   Typography,
   Card,
   MenuItem,
   FormControl,
   Select,
   TextareaAutosize,
   Dialog,
   Menu,
   Button,
   CircularProgress,
   Popover,
   Divider,
   DialogTitle,
   DialogContent,
   DialogActions,
   RadioGroup,
   FormControlLabel,
   Radio,
} from '@mui/material';

import _ from "lodash";
import { Tooltip } from "@mui/material";

import cost from '../../../assets/cost.svg';
import { useNavigate } from 'react-router-dom';
import contactImg from '../../../assets/FAB.png';
import { Container } from './style';
import backImg from '../../../assets/back.svg';
import up from '../../../assets/up.png';
import sendingImg from '../../../assets/sending.gif';
import verticalMenu from '../../../assets/verticalMenu.svg';
import scheduleImg from '../../../assets/icon.png';
import styled from '@emotion/styled';
import ScheduleDate from '../../../components/message/scheduleMessage/ScheduleDate';
import MessageContact from '../messageContact';
import MessageSummary from '../../../components/message/messageHome/MessageSummary';
import GroupSelect from '../../../components/message/select/GroupSelect';
import ContactContent from './ContactContent';
import { useDispatch, useSelector } from 'react-redux';
import { messageSelector } from '../../../components/utils/selectors/selectors';
import GroupContent from './GroupContent';
import {
   draftmessage,
   fetchsenderIDs,
   getMsgCost,
   resetCreated,
   schedulemessage,
   sendmessage,
   selectSenderId,
   resetSelectedContacts,
   updateSelectedContent
} from '../../../components/app/features/message/messageSlice';
import Success from './Success';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import NotificationPopup from '../../../components/NotificationPopup/NotificationPopup';
import { userLSAuth } from '../../../components/app/features/env';
import InsufficientSmsDialog from '../../../components/modal/InsufficientUnits';
import { setIn } from 'formik';


const Textarea = styled(TextareaAutosize)(
   ({ theme }) => `
		width: 100%;
		min-height: 150px;
      padding: 10px;
		border: 1px solid #A1A1A1;
		border-radius:5px;
		font-size:1rem;
      background: white;
      color: #49454F;
		margin-top: 2rem;
		&:focus {
      outline: none;
      box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
    },
     `
);
const DropDown = styled(Menu)(
   ({ theme }) => `
	position: absolute;
	right: 0;

& .MuiPopover-paper {
	border-radius:0;
}
& .MuiList-root, & .MuiMenu-list {
	padding:0;
	border-radius:0;
}

	`
);
const Activity = styled(MenuItem)(
   ({ theme }) => `
	min-height:unset;
	padding:5px 0 5px 15px;
 box-sizing: border-box;
 white-space: nowrap;
	width: 160px;
	font-weight:600;
	border-radius:0;
	`
);

const Message = () => {
   const selector = useSelector(messageSelector);
   const dispatch = useDispatch();
   const [anchorEl, setAnchorEl] = React.useState(null);
   const [period, setPeriod] = React.useState(false);
   const [draftOpen, setDraftOpen] = React.useState(false);
   const [add, setAdd] = React.useState(false);
   const [openSummary, setOpenSummary] = React.useState(false);
   const [selectGroup, setSelectGroup] = React.useState(false);
   const [open, setOpen] = React.useState(false);
   const [scheduleSuccess, setScheduleSuccess] = React.useState(false);
   const [schedule, setSchedule] = React.useState(false);
   const [action, setAction] = React.useState('');
   const [text, setText] = React.useState(selector.selectedContent);
   const [numOfContacts, setNumOfContacts] = React.useState(0);
   const [senderId, setSenderId] = React.useState(selector?.selectedSenderId);
   const [openProgress, setOpenProgress] = React.useState(false);
   const [sendProgress, setSendProgress] = React.useState(false);
   const actions = ['Save to Drafts'];
   const balance = userLSAuth()?.balance;
   const [totalCost, setotalCost] = React.useState('');
   const [insufficient, setInsufficient] = React.useState(false);
   const [maxCharactersPerPage, setMaxCharactersPerPage] = React.useState(160);
   const [messageId, setMessageId] = React.useState();
   const navigate = useNavigate();
   const [anchorEl2, setAnchorEl2] = React.useState(null);
   const [isTemplateDialogOpen, setIsTemplateDialogOpen] = React.useState(false);
   const [selectedTemplate, setSelectedTemplate] = React.useState(null);
   const [expandedId, setExpandedId] = React.useState(null);
   const isOpen = Boolean(anchorEl2);
   // Template data
   const templates = [
      {
         id: 1,
         category: "Easter",
         preview: "How do you avoid a bloody January after enjoying a detty December? You were not blaming village people...",
      },
      {
         id: 2,
         category: "Easter",
         preview: "Just like he rewrote the plot when he rolled the stone on the 3rd day, may this also be your season of beautiful turnarounds. Happy Easter Celebration!",
      },
      {
         id: 3,
         category: "Easter",
         preview: "He is risen! May the resurrection of Christ fill your heart with hope and renew your spirit. Wishing you a blessed and joyful Easter filled with love, grace, and faith.",
      },
      {
         id: 4,
         category: "Eid (Muslim holidays)",
         preview: "Ramadan is a time to come together in love, faith, and kindness. May this special month inspire compassion and strengthen the bonds we share. Ramadan Kareem!",
      },
      {
         id: 5,
         category: "Eid (Muslim holidays)",
         preview: "In this blessed month of Ramadan, the importance of gratitude, reflection, and giving is taught all over again. As you commemorate this season, may these virtues be once more inspired in you.",
      },
      {
         id: 6,
         category: "Eid (Muslim holidays)",
         preview: "As you go into the Ramadan season to reflect on your devotion to Allah, may you be granted clarity in all the ways you seek them. Ramadan Kareem!",
      },
      {
         id: 7,
         category: "Exam reminders",
         preview: "Here is a reminder for the forthcoming examinations which is to take place on the (date). Do well to study ahead in order to pass with flying colors.",
      },
      {
         id: 8,
         category: "Exam reminders",
         preview: "Examinations are not the test of intelligence but a proof of your preparedness for the next phase of life. Come prove to yourself on the (date) at (venue) that you are ready for the next step. Goodluck ahead!",
      },
      {
         id: 9,
         category: "Exam reminders",
         preview: "Dear students, this is a reminder to you that your (subject) examinations would still be holding on the (date) at (venue). We wish you the best ahead as you study efficiently ahead.",
      },
      {
         id: 10,
         category: "Fee payment notifications",
         preview: "Hello (Name), we hope you're doing well? This is a gentle reminder that your payment of (amount)  for (fee type)  is due on the [Due Date]. Please complete your payment at your earliest convenience to avoid possible consequences. Thank you!",
      },
      {
         id: 11,
         category: "Fee payment notifications",
         preview: "This is a kind reminder that your (Fee Type) payment of (Amount) is due on (Due Date). Kindly ensure payment is made by the due date to avoid late fees. Thank you!",
      },
      {
         id: 12,
         category: "Fee payment notifications",
         preview: "Dear (Name), your payment for (Fee Type) was due on (Due Date) and remains unpaid. Please make the payment by (Final Deadline) to avoid further action. We appreciate your immediate attention.",
      },
      {
         id: 13,
         category: "Webinar or workshop Invitations",
         preview: "Hi [Name]! Our [Topic] webinar/workshop on [Date] is almost full! Don’t miss your chance at scaling up your (workshop purpose), secure your spot now using [Registration Link]. We hope to see you there!",
      },
      {
         id: 14,
         category: "Webinar or workshop Invitations",
         preview: "You just secured a special invitation to our upcoming exclusive (topic) webinar/workshop. It’s happening on (date) at (time), and we’d love for you to be there. You have 24 hours to secure your spot here (registration link) before it’s closed!",
      },
      {
         id: 15,
         category: "Webinar or workshop Invitations",
         preview: "Hi (name), spots are filling up fast for our (topic) webinar/workshop holding on the (date) at (time). Register now via (registration link) before it’s too late. We’d love to have you join us!",
      },
      {
         id: 16,
         category: "Fundraising campaigns",
         preview: "Hi (Name), we’re reaching out because we know how passionate you are about (cause). Your support can help us achieve this [specific goal]. Please donate today via (link) and let’s make a real difference together.",
      },
      {
         id: 17,
         category: "Fundraising campaigns",
         preview: "Dear (Name), you’ve always been an admirable champion of (cause), and now we need your help more than ever. Your donation would go a long way in significantly achieving this (specific goal). Kindly send your contributions via (medium). Thank you for your continuous support!",
      },
      {
         id: 18,
         category: "Fundraising campaigns",
         preview: "Imagine a world where (impact of the campaign). We’re working hard to make that world a reality for (target audience), but we can’t do it without you as we believe your donation can help us bring change. Send in your donations via (Link). Thank you for your kindness!",
      },
      {
         id: 19,
         category: "Event invites",
         preview: "Great news! Here’s a cordial invite to (Event Name), a gathering of industry leaders and professionals happening on (date) at (time) at (Location). Don’t miss out on valuable insights and networking! RSVP now using the link or reply YES to confirm.  ",
      },
      {
         id: 20,
         category: "Event invites",
         preview: "Hey (name)! We’re bringing together amazing minds at (event name) for an evening of great conversations and connections. Join us on (date) at (time) at (venue) let’s make it happen! Click here (link) to secure a spot now!",
      },
      {
         id: 21,
         category: "Event invites",
         preview: "It’s a party!!! (Occasion name) would not be complete without you! Grace us with your presence  on the (date) at (time) at (venue) for a moment of fun, laughter, and good vibes. Show us we can count on you? RSVP now (link).",
      },
      {
         id: 22,
         category: "Volunteer recruitment",
         preview: "Would you like to join this generation of change makers to make a difference? Join us as a volunteer for (cause/event) by signing up via (link) immediately.",
      },
      {
         id: 23,
         category: "Volunteer recruitment",
         preview: "Dear (name), our organization needs people like you who are dedicated to humanity to lend a hand in order to change a life today. Volunteer with us at (organization name) using this link to sign up now!",
      },

   ];

   // Handle template selection
   const handleTemplateSelect = (event) => {
      setSelectedTemplate(Number(event.target.value));
   };

   // Show dialog
   const showTemplateDialog = () => {
      setIsTemplateDialogOpen(true);
   };

   // Hide dialog
   const hideTemplateDialog = () => {
      setIsTemplateDialogOpen(false);
   };

   // Use selected template
   const handleUseTemplate = () => {
      if (selectedTemplate) {
         const tem = templates.find(template => template.id === selectedTemplate)
         setText(tem.preview)
         hideTemplateDialog();
      }
   };



   const handlePreviewToggle = (id) => {
      setExpandedId(expandedId === id ? null : id);
   };

   const handleOpen = (event) => {
      setAnchorEl2(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl2(null);
   };



   const calculatePageCount = (text) => {
      let totalLength = text.length;
      let page = 1;
      while (totalLength > maxCharactersPerPage) {
         page++;
         totalLength -= maxCharactersPerPage;
      }
      return page;
   };


   const handleTextAreaChange = (e) => {
      const { value } = e.target;
      setMaxCharactersPerPage(value.length <= 160 ? 160 : 153);
      setText(value);
   };
   const pageCount = calculatePageCount(text);
   const remainingCharacters =
      maxCharactersPerPage - (text.length % maxCharactersPerPage);

   const handleChange = (event) => {
      setSenderId(event.target.value);
      dispatch(selectSenderId({ senderId: event.target.value }));
   };

   React.useEffect(() => {
      if (!selector.senderIds || selector.senderIds.length < 1) {
         dispatch(fetchsenderIDs());
      }
   }, []);

   React.useEffect(() => {
      if (selector.sent === true) {
         setOpenProgress(false);
         setOpen(true);
      } else {
         setOpen(false);
      }
   }, [selector.sent === true]);
   React.useEffect(() => {
      if (selector.sending === true) {
         setSendProgress(true);
         setOpenProgress(false);
      } else {
         setSendProgress(false);
         
      }
   }, [selector.sending === true]);

   React.useEffect(() => {
      console.log(selector.loading);
      if (selector.loading === 'pending' && selector.sending !== true) {
         setOpenProgress(true);
      } else {
         setOpenProgress(false);
      }
   }, [selector.loading === 'pending' && selector.sending !== true]);

   React.useEffect(() => {
      if (selector.scheduled === true) {
         setScheduleSuccess(true);
      } else {
         setScheduleSuccess(false);
      }
   }, [selector.scheduled === true]);

   React.useEffect(() => {
      if (action === 'Save to Drafts') {
         setDraftOpen(true);
      }
   }, [action === 'Save to Drafts']);



   React.useEffect(() => {
      const handleBeforeUnload = (event) => {
         event.preventDefault();
         if (text !== '') {
            autoSaveDraft();
         }
      };

      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
         if (location.pathname !== '/send-message/message') {
            autoSaveDraft();
         }
         window.removeEventListener('beforeunload', handleBeforeUnload);
      };
   }, [text]);

   const forwardMessage = (values) => {
      dispatch(sendmessage(values))
         .then((response) => {
            setMessageId(response?.payload?.data?.id)

         })
   };

   const groupIds = selector?.selectedGroups.map((group) => group.id);
   const contactIds = selector?.selectedContacts.map((contact) => contact.id);
   const numbers = selector?.selectedNumbers;

   const newDate = dayjs(new Date());
   const currentDate = newDate.toISOString();
   const [date, setDate] = React.useState(currentDate);




   const scheduleFun = () => {



      dispatch(updateSelectedContent(text));
   };

   const getTime = (value) => {
      setDate(value);
      setPeriod(false);
   };



   const closePeriod = () => setPeriod(false);
   const draft = () => {
      if (text !== '') {
         dispatch(
            draftmessage({
               content: text,
               senderId: senderId,
               scheduledAt: currentDate,
               contactids: contactIds,
               groupids: groupIds,
               numbers,
            })
         );
         window.history.back();
      } else {
         toast.info('type a message');
      }
   };
   const autoSaveDraft = () => {
      if (text !== '') {
         dispatch(
            draftmessage({
               content: text,
               senderId: senderId,
               scheduledAt: currentDate,
               contactids: contactIds,
               groupids: groupIds,
               numbers,
            })
         );
         // window.history.back();
      }
   };

   const handleMenu = (value) => {
      setAction(value);
      setAnchorEl(null);
   };
   const setClose = () => setAnchorEl(false);

   const send = (values) => {
      if (text !== '' && schedule === false) {
         forwardMessage(values);
         setText('');

      } else if (text !== '' && schedule === true) {
         dispatch(schedulemessage(values));
         setText('');
         setSchedule(false);
      } else {
         toast.info('type a message');
      }
   };



   // React.useEffect(() => {
   //    dispatch(
   //       getMsgCost({
   //          content: '',
   //          senderId: senderId,
   //          scheduledAt: date,
   //          contactids: contactIds,
   //          groupids: groupIds,
   //          numbers,
   //       })
   //    );
   // }, [selector?.selectedContacts || selector?.selectedGroups]);

   React.useEffect(() => {
      if (selector.msgCost?.dNDCount || selector.msgCost?.nonDNDCount) {
         setNumOfContacts(
            selector.msgCost.dNDCount + selector.msgCost.nonDNDCount
         );
      } else {
         setNumOfContacts(0);
         setNumOfContacts(0);
      }
   }, [selector.msgCost]);

   const resetInsufficient = () => {
      setInsufficient(false);
   };

   const resetMsgSummary = () => {
      setOpenSummary(false);
   };

   return (
      <Container>

         <NotificationPopup />
         <Card
            sx={{
               width: '90%',
               maxWidth: '655px',
               minHeight: '80vh',
               margin: '0 auto',
               marginTop: '2rem',
               position: 'relative',
               boxShadow:
                  '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
               padding: '20px ',
               background: '#E8E8E8',
            }}
         >
            <Box
               sx={{
                  margin: '0 auto',
                  marginTop: '1rem',
                  border: '1px solid transparent',
                  position: 'relative',
               }}
            >
               <img
                  src={backImg}
                  alt="back"
                  style={{
                     position: 'absolute',
                     width: '40px',
                     cursor: 'pointer',
                     // border: '1px solid',
                  }}
                  onClick={() => {
                     if (text !== '') {
                        setDraftOpen(true);
                     } else {
                        window.history.back();
                     }
                  }}
               />
               <DropDown
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={setClose}
                  MenuListProps={{
                     'aria-labelledby': 'basic-button',
                  }}
               >
                  {actions.map((action, index) => (
                     <Activity
                        key={index}
                        onClick={(e) => handleMenu(e.target.innerText)}
                     >
                        {action}
                     </Activity>
                  ))}
               </DropDown>
               <img
                  src={verticalMenu}
                  alt="back"
                  style={{
                     position: 'absolute',
                     width: '40px',
                     right: 0,
                     filter:
                        'invert(27%) sepia(11%) saturate(7243%) hue-rotate(207deg) brightness(94%) contrast(88%)',
                     cursor: 'pointer',
                  }}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
               />
               <Typography
                  sx={{
                     width: 'max-content',
                     margin: '0 auto',
                     fontWeight: 600,
                     fontSize: 'clamp(1.1rem, 2vw, 1.25rem)',
                     color: '#0F0E0E',
                     marginTop: '3rem',
                  }}
               >
                  Send Message
               </Typography>

               <Typography
                  sx={{
                     margin: '1.5rem 0 0.5rem 0',
                     fontWeight: 600,
                     fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                     color: '#0F0E0E',
                     fontFamily: "'Raleway', 'Montserrat', sans-serif",
                  }}
               >
                  Sender ID
               </Typography>
               <FormControl fullWidth>
                  <Select
                     id="demo-simple-select"
                     value={senderId}
                     onChange={handleChange}
                     sx={{
                        backgroundColor: '#fff',
                        fontWeight: 600,
                        padding: '25px 0 !important',
                        fontFamily: "'Raleway', 'Montserrat', sans-serif",
                        '& .MuiOutlinedInput-notchedOutline': {
                           borderColor: '#A1A1A1',
                        },
                     }}
                  >
                     {selector?.senderIds ? (
                        selector?.senderIds
                           .filter((x) => x.status == 'approved')
                           .map((senderId, index) => (
                              <MenuItem value={senderId?.senderId} key={index}>
                                 {senderId?.senderId}
                              </MenuItem>
                           ))
                     ) : (
                        <MenuItem value={1}>No Sender Ids</MenuItem>
                     )}
                  </Select>
               </FormControl>

               <Box
                  sx={{
                     display: 'flex',
                     justifyContent: 'space-between',
                  }}
               >
                  <Typography
                     sx={{
                        margin: '1.5rem 0 0.5rem 0',
                        fontWeight: 600,
                        fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                        color: '#0F0E0E',
                     }}
                  >
                     Recipients
                  </Typography>
                  <Typography
                     sx={{
                        margin: '1.5rem 0 0 0',
                        fontWeight: 400,
                        color: '#374da0',
                        cursor: 'pointer',
                     }}
                     onClick={() => {
                        dispatch(resetSelectedContacts());
                     }}
                  >
                     Clear
                  </Typography>
               </Box>
               <Box
                  sx={{
                     display: 'flex',
                     justifyContent: 'space-between',
                     position: 'relative',
                  }}
               >
                 

                  <Box
                     sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignContent: 'flex-start',
                        bgcolor: 'background.paper',
                        maxHeight: 10,
                        border: '1px solid #A1A1A1',
                        minHeight: '60px',
                        background: '#fff',
                        borderRadius: '5px',
                        padding: '10px',
                        paddingRight: '40px',
                        overflow: 'auto',
                        width: '100%',
                        scrollbarWidth: 'thin',
                        scrollbarColor: 'grey',
                        // '&::-webkit-scrollbar': {
                        //    width: 0,
                        //    height: 0,
                        // },
                     }}
                  >
                     {selector?.selectedGroups.length > 0
                        ? selector?.selectedGroups.map((item, id) => (
                           <GroupContent key={id} item={item} />
                        ))
                        : null}
                     {selector.selectedContacts.length > 0
                        ? selector.selectedContacts.map((item, id) => (
                           <ContactContent key={id} item={item} />
                        ))
                        : null}

                     {selector.selectedNumbers.length > 0
                        ? selector.selectedNumbers.map((item, id) => (
                           <ContactContent key={id} item={{ number: item }} />
                        ))
                        : null}
                  </Box>
                  <img
                     src={contactImg}
                     alt="icon"
                     style={{
                       
                       
                       
                        
                        
                        cursor: 'pointer',
                     }}
                     onClick={() => {
                       
                        dispatch(updateSelectedContent(text));
                        setAdd(true);
                     }}
                  />
               </Box>

               <Typography
                  sx={{
                     margin: '1rem 0',
                     display: 'flex',
                     fontWeight: 600,
                     color: '#374da0',
                     cursor: 'pointer',
                     width: 'max-content',
                  }}
                  onClick={() => {
                     dispatch(
                        getMsgCost({
                           content: text,
                           senderId: senderId,
                           scheduledAt: date,
                           contactids: contactIds,
                           groupids: groupIds,
                           numbers,
                        })
                     );
                     setOpenSummary(true);
                  }}
               >
                  <img src={cost} alt="icon" style={{ marginRight: '10px' }} />
                  View Pricing Info
               </Typography>

               <Typography
                  sx={{
                     fontSize: '1rem',
                     color: '#555',
                     display: 'inline',
                     fontWeight: 600,
                     mt: 2,
                  }}
               >
                  Avoid use of{' '}
                  <strong style={{ fontWeight: 800, fontSize: '1.3rem' }}>
                     "&"
                  </strong>{' '}
                  as it truncates the message.{' '}
               </Typography>

               <Link
                  to="/send-message/rules"
                  style={{ textDecoration: 'none' }}
               >
                  <Typography
                     sx={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        color: '#1E90FF',
                        display: 'inline',
                        fontSize: '1.05rem',
                        fontWeight: 600,
                     }}
                  >
                     Learn more...
                  </Typography>
               </Link>
               <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1rem', marginBottom: '1rem' }}>
                  <Typography sx={{ fontSize: '1.2rem', color: '#0F0E0E', fontWeight: '500' }}>
                     Your Message
                  </Typography>
                  <Typography
                     sx={{ fontSize: '1rem', textDecoration: 'underline', cursor: 'pointer', color: '#374DA0', fontWeight: '500' }}
                     onClick={showTemplateDialog}
                  >
                     Open Templates
                  </Typography>
               </Box>


               <Textarea
                  style={{ marginTop: '0 ' }}
                  name="desc"
                  aria-label="about"
                  minRows={1}
                  // placeholder='Enter message here...'
                  onChange={handleTextAreaChange}
                  value={text}
                  variant="outlined"
                  id="group_desc"
               />

               <Box
                  sx={{
                     display: 'flex',
                     justifyContent: 'space-between',
                     marginBottom: '2rem',
                  }}
               >
                  <Typography
                     sx={{
                        width: 'max-content',
                        color: '#374DAO',
                        fontWeight: '600',
                     }}
                  >
                     {remainingCharacters}/{pageCount}
                  </Typography>
                  <Typography
                     sx={{
                        width: 'max-content',
                        color: '#374DAO',
                        fontWeight: '600',
                     }}
                  >
                     {`contacts:`}
                     {numOfContacts}
                  </Typography>
               </Box>

               <Button
                  disabled={text.length === 0}
                  sx={{
                     width: '300px',

                     margin: '1.5rem auto',
                     borderRadius: '8px',
                     backgroundColor: '#374DA0',
                     color: '#fff',
                     display: 'flex',
                     justifyContent: 'center',
                     ':hover': {
                        backgroundColor: '#374DA0',
                     },
                     '@media(max-width:500px)': {
                        width: '100%',
                     },
                  }}
               >
                  <Typography
                     sx={{
                        textAlign: 'center',
                        width: '80%',
                        padding: '10px',
                        cursor: 'pointer',

                     }}
                     onClick={async () => {
                        try {
                          // Properly wait for the thunk to complete and get its result
                          const resultAction = await dispatch(
                            getMsgCost({
                              content: text,
                              senderId,
                              scheduledAt: date,
                              contactids: contactIds,
                              groupids: groupIds,
                              numbers,
                            })
                          );
                          
                          // Check if the action was fulfilled (not rejected)
                          if (getMsgCost.fulfilled.match(resultAction)) {
                            const updatedCost = resultAction.payload.totalCost || 0;
                            const userBalance = userLSAuth()?.balance || 0;
                            
                            console.log(updatedCost, userBalance);
                            
                            if (updatedCost > userBalance) {
                              setInsufficient(true);
                              return;
                            }
                            
                            // If balance is sufficient, proceed with sending
                            dispatch(send({
                              content: text,
                              senderId,
                              scheduledAt: date,
                              contactids: contactIds,
                              groupids: groupIds,
                              numbers,
                            }));
                          }
                        } catch (error) {
                          console.error("An error occurred:", error);
                        }
                      }} >          
                  
                     Send Message
                  </Typography>
                  <Divider orientation="vertical" flexItem sx={{ height: '30px', margin: '0 10px', background: "grey" }} />


                  <Typography
                     onClick={handleOpen}
                  >
                     <img
                        src={up}
                        alt="back"
                        style={{

                           cursor: 'pointer',
                           // border: '1px solid',
                        }}

                     />

                  </Typography>


               </Button>
               <Popover
                  open={isOpen}
                  anchorEl={anchorEl2}
                  onClose={handleClose}
                  anchorOrigin={{
                     vertical: "top",
                     horizontal: "center",
                  }}
                  PaperProps={{
                     sx: {
                        borderRadius: 0.5,
                        mt: -2.3,
                        ml: -7,
                        padding: 0.6,
                        backdropFilter: "none",
                        filter: "none",

                     },
                  }}
                  transformOrigin={{
                     vertical: "bottom",
                     horizontal: "center",
                  }}
               >
                  <Typography
                     sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderLeft: "1px solid #fff",
                        padding: "10px",
                        cursor: "pointer",
                        gap: "8px",

                     }}
                     onClick={() => {
                        navigate('/send-message/schedule-message');
                        scheduleFun();
                     }}
                  >

                     <img src={scheduleImg} alt="icon" />
                     <Typography style={{ fontWeight: "700", fontSize: '0.8rem', color: '#374DA0' }}>Schedule Message</Typography>
                  </Typography>
               </Popover>
               <Dialog onClose={() => setPeriod(false)} open={period}>
                  <ScheduleDate getTime={getTime} closePeriod={closePeriod} />
               </Dialog>
               <Dialog
                  onClose={() => {
                     setAction('');
                     setDraftOpen(false);
                  }}
                  open={draftOpen}
               >
                  <Card sx={{ padding: '20px 55px' }}>
                     <Typography
                        sx={{
                           textAlign: 'center',
                           marginBottom: '2rem',
                           fontWeight: 600,
                           fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                        }}
                     >
                        Save this message as a draft?
                     </Typography>
                     <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography
                           sx={{
                              fontWeight: 600,
                              fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                              color: '#374DAO',
                              marginRight: '1rem',
                              cursor: 'pointer',
                           }}
                           onClick={() => draft()}
                        >
                           Save as Draft
                        </Typography>
                        <Typography
                           sx={{
                              fontWeight: 600,
                              fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                              color: '#374DAO',
                              cursor: 'pointer',
                           }}
                           onClick={() => window.history.back()}
                        >
                           Discard
                        </Typography>
                     </Box>
                  </Card>
               </Dialog>
               <Dialog onClose={() => setAdd(false)} open={add}>
                  <MessageContact
                     contactText={'/send-message/select-contact'}
                     groupsText={'/send-message/select-group'}
                     pasteText={'/send-message/select-paste'}
                  />
               </Dialog>
               <Dialog onClose={() => setSelectGroup(false)} open={selectGroup}>
                  <GroupSelect />
               </Dialog>
               <Dialog onClose={() => setOpenSummary(false)} open={openSummary}>
                  <MessageSummary resetMsgSummary={resetMsgSummary} />
               </Dialog>
               <Dialog
                  onClose={() => {
                     setOpen(false);
                     dispatch(resetCreated());
                  }}
                  open={open}
               >
                  <Success id={messageId} msgType="Message Sent!" />
               </Dialog>


               <Dialog
                  onClose={() => setScheduleSuccess(false)}
                  open={scheduleSuccess}
               >
                  <Success msgType="Message Scheduled Successfully!" />
               </Dialog>

               <Dialog
                  onClose={() => setInsufficient(false)}
                  open={insufficient}
               >
                  <InsufficientSmsDialog
                     resetInsufficient={resetInsufficient}
                  />
               </Dialog>

               <Dialog
                  onClose={() => {
                     setOpenProgress(false);
                  }}
                  open={openProgress}
               >
                  <div
                     style={{
                        background: '#555',
                        overflowX: 'hidden',
                        overflowY: 'hidden',
                        background: 'none',
                        padding: '10px',
                        backgroundColor: '#374DA0',
                     }}
                  >
                     <CircularProgress
                        style={{
                           color: 'white',
                        }}
                     />
                  </div>
               </Dialog>




               <Dialog
                  onClose={() => {
                     setSendProgress(false);
                  }}
                  open={sendProgress}
               >
                  <div
                     style={{

                        padding: '10px',

                        textAlign: 'center',
                     }}
                  >
                     <img
                        src={sendingImg}
                        alt="Sending"
                        style={{
                           width: '100%',
                           maxWidth: '25rem',
                           height: 'auto',
                           padding: '0 2rem'
                        }}

                     />
                     <Box
                        sx={{
                           display: "flex",
                           justifyContent: "center",
                           alignItems: "center",
                           fontWeight: "bold",
                           fontSize: {
                              xs: "1rem",
                              sm: "1.25rem",
                              md: "1.5rem",
                           },
                           textAlign: "center",
                           padding: {
                              xs: "1.5rem",
                              sm: "2rem",
                              md: "3rem",
                           },
                        }}
                     >
                        Sending Message...
                     </Box>


                  </div>
               </Dialog>
               <Dialog
                  open={isTemplateDialogOpen}
                  onClose={hideTemplateDialog}
                  maxWidth="sm"
                  fullWidth
                  PaperProps={{
                     sx: {
                        borderRadius: 2,
                        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
                        background: '#f7f7f8'
                     }
                  }}
               >
                  <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: 1 }}>
                     <Typography variant="h6" sx={{ marginY: '0.5rem', fontWeight: '500', color: '#333333', fontSize: '1rem' }} component="div">
                        Browse Templates
                     </Typography>

                  </DialogTitle>
                  <DialogContent sx={{ pt: 0, pb: 0, mb: 0 }}>
                     <RadioGroup value={selectedTemplate || ""} onChange={handleTemplateSelect}>
                        {templates.map((template) => {
                           const isSelected = selectedTemplate === template.id;
                           const isExpanded = expandedId === template.id;
                           const previewText = isExpanded ? template.preview : _.truncate(template.preview, { length: 80 });

                           return (
                              <Box
                                 key={template.id}
                                 sx={{
                                    py: 2,
                                    px: 2,
                                    mb: 1,

                                    borderRadius: 1,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    backgroundColor: isSelected ? "#374DA0" : "white",
                                    color: isSelected ? "white" : "inherit",

                                 }}
                              >
                                 <Box>
                                    <Typography
                                       variant="subtitle1"
                                       sx={{
                                          fontWeight: "500",
                                          fontSize: '1rem',
                                          color: isSelected ? "white" : "#333333",
                                       }}
                                    >
                                       {template.category}
                                    </Typography>

                                    {/* Dynamic preview (truncated or full) */}
                                    <Typography
                                       variant="body2"
                                       sx={{
                                          maxWidth: "90%",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          font: '400',
                                          color: isSelected ? "rgba(255, 255, 255, 0.8)" : "#5F5E5E",
                                       }}
                                    >
                                       {previewText}
                                       <Link
                                          style={{ marginLeft: '3px', color: isSelected ? "rgba(255, 255, 255, 0.9)" : "#374DA0", textDecoration: "underline", }}
                                          href="#"
                                          onClick={(e) => {
                                             e.preventDefault();
                                             handlePreviewToggle(template.id);
                                          }}
                                          sx={{ color: isSelected ? "rgba(255, 255, 255, 0.9)" : undefined, fontSize: "12px", display: "block", mt: 1 }}
                                       >
                                          {isExpanded ? "Show less" : "Preview"}
                                       </Link>
                                    </Typography>



                                 </Box>

                                 <Box sx={{ display: "flex", gap: 1 }}>
                                    <FormControlLabel
                                       value={template.id}
                                       control={
                                          <Radio
                                             sx={{
                                                color: isSelected ? "white" : undefined,
                                                "&.Mui-checked": {
                                                   color: isSelected ? "white" : undefined,
                                                },
                                             }}
                                          />
                                       }
                                       label=""
                                       sx={{ ml: 0, mr: 0 }}
                                    />
                                 </Box>
                              </Box>
                           );
                        })}
                     </RadioGroup>
                  </DialogContent>;

                  <DialogActions sx={{ px: 1, pb: 2, pt: 0, marginRight: 2 }}>
                     <Button
                        onClick={hideTemplateDialog}
                        sx={{
                           borderRadius: 1,
                           px: 1,
                           py: 0.5,
                           fontSize: '1rem',
                           textTransform: 'none',
                           color: '#374DA0',
                        }}
                     >
                        Cancel
                     </Button>
                     <Button
                        onClick={handleUseTemplate}
                        disabled={!selectedTemplate}
                        variant="outlined"
                        sx={{
                           borderRadius: 1,
                           px: 1.5,
                           py: 0.5,
                           fontSize: '1rem',
                           borderColor: '#5d69e3',
                           color: '#5d69e3',
                           textTransform: 'none',
                           '&:hover': {
                              backgroundColor: '#e9eafb',
                              borderColor: '#374DA0',
                           },

                        }}
                     >
                        Use Template
                     </Button>
                  </DialogActions>

               </Dialog>
            </Box>
         </Card>
      </Container>
   );
};

export default Message;
