import React from 'react';
import { styled, Box, Card, Typography } from '@mui/material';
import backImg from '../../assets/back.svg';
import editIcon from '../../assets/profile.svg';
import idIcon from '../../assets/id.svg';
import activityIcon from '../../assets/activityHistory.svg';
import pHIcon from '../../assets/paymentHistory.svg';
import mHIcon from '../../assets/messageHistorii.svg';
import faq from '../../assets/faq.svg';
import contactIcon from '../../assets/contactUs.svg';
import feature from '../../assets/feature.svg';
import about from '../../assets/about.svg';
import legal from '../../assets/legal.svg';
import privacy from '../../assets/privacy.svg';
import phone from '../../assets/uil_phone.svg';
import ArchiveIcon from '../../assets/archive.svg';
import Key from '../../assets/key.png';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userLSAuth } from '../../components/app/features/env';

export const Wrapper = styled(Box)(
   ({ theme }) => `
background: #EFEEF4;  
min-height: 100vh;  
padding-top:${theme.header.height}; 
`
);
export const Container = styled(Box)(
   ({ theme }) => `
 padding:  2rem 0; 
 min-height: 80vh; 
 background: #E8E8E8;
 `
);

const Settings = () => {
   // const selector = useSelector(getAuthSelector);
   const actions = [
      {
         title: 'My Profile',
         nav: [
            {
               title: 'Edit Profile',
               icon: editIcon,
            },
            {
               title: 'Apply for Sender ID',
               icon: idIcon,
            },
            {
               title: 'Verify Phone Number',
               icon: phone,
            },
         ],
      },
      {
         title: 'Activity',
         nav: [
            {
               title: 'Activity History',
               icon: activityIcon,
            },
            {
               title: 'Payment History',
               icon: pHIcon,
            },
            {
               title: 'Message History',
               icon: mHIcon,
            },
            {
               title: 'Message Archives',
               icon: ArchiveIcon,
            },
         ],
      },
      {
         title: 'Help & Support',
         nav: [
            {
               title: 'FAQs',
               icon: faq,
            },
            {
               title: 'Contact us',
               icon: contactIcon,
            },
            {
               title: 'Suggest a feature',
               icon: feature,
            },
         ],
      },
      {
         title: 'More Information',
         nav: [
            {
               title: 'About',
               icon: about,
            },
            {
               title: 'Legal Terms of Use',
               icon: legal,
            },
            {
               title: 'Data & Privacy Policy',
               icon: privacy,
            },
            {
               title: 'API Settings',
               icon: Key,
            },
         ],
      },
   ];
   const navigate = useNavigate();
   const [action, setAction] = useState('');

   React.useEffect(() => {
      if (action === 'Edit Profile') {
         navigate('/settings/profile');
      } else if (action === 'Apply for Sender ID') {
         navigate('/send-message/verify');
      } else if (action === 'Activity History') {
         navigate('/dashboard/activity');
      } else if (action === 'Payment History') {
         navigate('/top-up/transactions');
      } else if (action === 'Message History') {
         navigate('/send-message/history');
      } else if (action === 'FAQs') {
         window.open('https://parrotica.com/faq.html', '_blank');
      } else if (action === 'Contact us') {
         navigate('/dashboard/contact');
      } else if (action === 'Suggest a feature') {
         navigate('/dashboard/suggest');
      } else if (action === 'About') {
         window.open('https://parrotica.com/about.html', '_blank');
      } else if (action === 'Legal Terms of Use') {
         window.open('https://parrotica.com/terms.html', '_blank');
      } else if (action === 'Data & Privacy Policy') {
         window.open('https://parrotica.com/policy.html', '_blank');
      } else if (action === 'Verify Phone Number') {
         navigate('/settings/phone-verify');
      } else if (action === 'Message Archives') {
         navigate('/settings/archive');
      }
       else if (action === 'API Settings') {
         navigate('/dashboard/api');
      }
   }, [action]);

   return (
      <Wrapper>
         <Container>
            <Card
               sx={{
                  // border: '1px solid red',
                  width: '90%',
                  maxWidth: '615px',
                  minHeight: '80vh',
                  margin: '0 auto',
                  position: 'relative',
                  boxShadow:
                     '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
                  padding: '25px ',
                  background: '#E8E8E8',
               }}
            >
               <img
                  src={backImg}
                  alt="back"
                  style={{
                     position: 'absolute',
                     width: '40px',
                     cursor: 'pointer',
                  }}
                  onClick={() => window.history.back()}
               />
               <Box
                  sx={{
                     width: '80px',
                     height: '80px',
                     borderRadius: '50%',
                     background: '#fff',
                     margin: '0 auto',
                  }}
               >
                  <img
                     style={{
                        width: '100%',
                        height: '100%',
                        display: 'block',
                        borderRadius: '50%',
                     }}
                     src={userLSAuth()?.displayPicture}
                     alt="icon"
                  />
               </Box>
               <Typography
                  sx={{
                     textAlign: 'center',
                     marginTop: '1rem',
                     fontSize: '1.15rem',
                     fontFamily: "'Raleway', 'Montserrat', sans-serif",
                     fontWeight: 600,
                  }}
               >
                  {userLSAuth()?.fullName || 'pal'}
               </Typography>
               <Typography
                  sx={{
                     textAlign: 'center',
                     fontSize: '0.9rem',
                     fontFamily: "'Raleway', 'Montserrat', sans-serif",
                     // fontWeight: 600,
                  }}
               >
                  {userLSAuth()?.email || 'pal@gmail.com'}
               </Typography>

               <Box sx={{ marginTop: '1rem' }}>
                  {actions.map((item, id) => (
                     <Box sx={{ mt: 2 }} key={id}>
                        <Typography
                           sx={{
                              fontSize: '1.15rem',
                              fontFamily: "'Raleway', 'Montserrat', sans-serif",
                              fontWeight: 600,
                           }}
                        >
                           {item.title}
                        </Typography>
                        <Box
                           sx={{
                              background: '#fff',
                              mt: 1,
                              padding: '15px',
                              borderRadius: '10px',
                           }}
                        >
                           {item.nav.map((elem) => (
                              <Typography
                                 sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '10px',
                                    borderBottom: '1px solid #D0D0D0',
                                    paddingBottom: '10px',
                                    fontSize: '1rem',
                                    cursor: 'pointer',
                                 }}
                                 key={elem.title}
                                 onClick={(e) => setAction(e.target.innerText)}
                              >
                                 <img
                                    src={elem.icon}
                                    style={{
                                       width: '25px',
                                       marginRight: '15px',
                                    }}
                                    alt="icon"
                                 />
                                 {elem.title}
                              </Typography>
                           ))}
                        </Box>
                     </Box>
                  ))}
               </Box>
            </Card>
         </Container>
      </Wrapper>
   );
};

export default Settings;
