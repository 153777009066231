import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
    CircularProgress,
    Backdrop
} from "@mui/material";
import axios from "axios";
import backImg from "../../assets/back.svg";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { messageSelector } from "../../components/utils/selectors/selectors";
import { falsifyCreated, suggestFeature } from "../../components/app/features/message/messageSlice";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Visibility, VisibilityOff, ContentCopy } from "@mui/icons-material";
import { userLSAuth, getBaseUrls } from "../../components/app/features/env";
import CloseIcon from "@mui/icons-material/Close";

import {
    Apikey, DisableApikey, getApiKey
} from '../../components/app/features/message/messageSlice';

import { toast } from "react-toastify";

export const Container = styled(Box)`
    padding: 2rem 0;
    min-height: 100vh;
    background: #e8e8e8;
`;

const TextInput = styled(TextField)`
    background: white;
    color: #49454f;
`;

const FlatButton = styled(Button)`
    background: #374da0;
    color: #fff;
    font-weight: 600;
    width: 60%;
    text-align: center;
    font-size: 0.9rem;
    border-radius: 10px;
    margin: 1rem auto;
    &:hover {
        background: #374da0;
    }
`;

const Api = () => {
    const dispatch = useDispatch();
    const selector = useSelector(messageSelector);
    const [showPassword, setShowPassword] = useState(false);
    const [apiKey, setApiKey] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [openToggleDialog, setOpenToggleDialog] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const [data, setdata] = useState({ disabled: false });

    const handleToggleVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(apiKey);
        toast.success("API Key copied!");
    };

    useEffect(() => {
        const GetApi = async () => {
            try {
                const response = await dispatch(getApiKey());
                console.log(response?.payload);
                setApiKey(response?.payload?.ApiKey)
                setdata(response?.payload)
            } catch (error) {
                console.error("Error fetching", error);
            }
        };

        GetApi();
    }, []);

    const GenerateApi = async () => {
        try {
            setIsGenerating(true);
            const response = await dispatch(
                Apikey({
                    userId: userLSAuth()?.id,
                })
            );

            if (response?.payload?.data?.ApiKey) {
                setApiKey(response.payload.data.ApiKey);
                setOpenDialog(false);
                console.log("API Key:", response.payload.data.ApiKey);
                toast.success("API Key generated successfully!");
                return response.payload.data.ApiKey;
            } else {
                console.error("API Key not found in response");
                toast.error("Failed to generate API Key");
            }
        } catch (error) {
            console.error("Error generating API key:", error);
            toast.error("Error generating API Key");
        } finally {
            setIsGenerating(false);
        }
    };

    const openToggleConfirmation = () => {
        setOpenToggleDialog(true);
    };

    const DisableKey = async () => {
        try {
            setIsGenerating(true);
            setOpenToggleDialog(false);

            const response = await dispatch(
                DisableApikey({
                    disabled: !data.disabled
                })
            );

            if (response) {

                setdata(prevData => ({
                    ...prevData,
                    disabled: !prevData.disabled
                }));

                if (data.disabled) {
                    toast.success("API Key enabled successfully!");
                } else {
                    toast.success("API Key disabled successfully!");
                }
            } else {
                console.error("API Key not found in response");
                toast.error("Failed to toggle API Key status");
            }
        } catch (error) {
            console.error("Error toggling API key:", error);
            toast.error("Error toggling API Key status");
        } finally {
            setIsGenerating(false);
        }
    };

    // Helper function to correctly determine the button text
    const getToggleButtonText = () => {
        const isDisabled = data?.disabled === true || data?.disabled === "true";
        return isDisabled ? "Enable Key" : "Disable Key";
    };

    return (
        <Container>
            {/* Loading Dialog with Spinner */}
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backdropFilter: 'blur(3px)'
                }}
                open={selector.loading === 'pending' || isGenerating}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        background:'white',
                        padding: 1,
                        borderRadius: 2,
                        boxShadow: 3
                    }}
                >
                    <CircularProgress sx={{ color: "#374DA0" }} size={40} thickness={4} />
                    <Typography sx={{ color: "#333", fontWeight: 500 }}>
                      
                    </Typography>
                </Box>
            </Backdrop>

            <Card
                sx={{
                    width: "90%",
                    maxWidth: "615px",
                    minHeight: "80vh",
                    margin: "0 auto",
                    marginTop: "5rem",
                    position: "relative",
                    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
                    padding: "20px",
                    background: "#E8E8E8",
                }}
            >
                <Box sx={{ margin: "0 auto", marginTop: "1rem", position: "relative" }}>
                    <img
                        src={backImg}
                        alt="back"
                        style={{
                            position: "absolute",
                            width: "40px",
                            cursor: "pointer",
                        }}
                        onClick={() => window.history.back()}
                    />
                    <Typography
                        sx={{
                            width: "max-content",
                            margin: "0 auto",
                            fontWeight: 600,
                            fontSize: "clamp(1.1rem, 2vw, 1.25rem)",
                            color: "#0F0E0E",
                            fontFamily: "'Raleway', 'Montserrat', sans-serif",
                            marginTop: "2rem",
                        }}
                    >
                        API Settings
                    </Typography>
                    {apiKey?.length > 0 && (
                        <div>



                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontFamily: "'Raleway', 'Montserrat', sans-serif",
                                    fontSize: "0.9rem",
                                    mb: "5px",
                                    mt: "1rem",
                                }}
                            >
                                User ID
                            </Typography>
                            <TextInput
                                fullWidth
                                margin="normal"
                                name="email"
                                value={userLSAuth()?.id || ""}
                                placeholder="Enter your email"
                                type="email"
                                variant="outlined"
                                sx={{ backgroundColor: "white" }}
                                disabled
                            />

                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontFamily: "'Raleway', 'Montserrat', sans-serif",
                                    fontSize: "0.9rem",
                                    mt: 2,
                                    mb: "5px",
                                }}
                            >
                                Active API Key
                            </Typography>

                            <TextField
                                type={showPassword ? "text" : "password"}
                                fullWidth
                                value={apiKey}
                                disabled
                                sx={{ backgroundColor: "white" }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleToggleVisibility} edge="end">
                                                {!showPassword ? <VisibilityOff /> : <Visibility sx={{ color: "#374ea1" }} />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 2 }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleCopy}
                                    disabled={!apiKey}
                                    sx={{ fontSize: "0.9rem", padding: "6px 12px" }}
                                >
                                    Copy Key
                                </Button>
                                <Button
                                    onClick={openToggleConfirmation}
                                    variant="outlined"
                                    color="primary"
                                    disabled={!apiKey}
                                    sx={{ fontSize: "0.9rem", padding: "6px 12px" }}
                                >
                                    {getToggleButtonText()}
                                </Button>
                            </Box></div>)
                    }
                    <Box sx={{ width: "100%", maxWidth: 600, mx: "auto", py: 2 }}>
                        <Box
                            sx={{
                                backgroundColor: "white",
                                border: "1px solid #ccc",
                                width: "100%",
                                px: 2,
                                py: 1,
                                mb: 2,
                                cursor: "pointer",
                            }}
                            onClick={() => setOpenDialog(true)}
                        >
                            <Typography variant="body1">Generate API Key</Typography>
                        </Box>
                        <a href="https://parrotica.com/developer.html">
                            <Box
                                sx={{
                                    backgroundColor: "white",
                                    border: "1px solid #ccc",
                                    width: "100%",
                                    px: 2,
                                    py: 1,
                                }}
                            >
                                <Typography variant="body1"> View API Documentation </Typography>
                            </Box>
                        </a>
                    </Box>
                </Box>
            </Card>

            {/* Dialog for Generating API Key */}
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}
                maxWidth="sm"
                fullWidth={true}
                sx={{ "& .MuiDialog-paper": { maxWidth: "400px" } }}
            >
                <DialogTitle>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography sx={{ fontWeight: '600', fontSize: '1.1rem', color: '#333333' }}>
                            Generate API Key
                        </Typography>

                        <IconButton onClick={() => setOpenDialog(false)} size="small">
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <hr />
                <DialogContent>
                    <Typography sx={{ fontSize: '0.9rem', fontWeight: '500', color: '#333333' }}>
                        Are you sure you want to generate a new API key? This will replace your current key.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ color: '#374DA0', fontWeight: '600', fontSize: '1rem' }} onClick={() => setOpenDialog(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button
                        onClick={GenerateApi}
                        sx={{
                            background: '#374DA0',
                            fontWeight: 600,
                            fontSize: '1rem',
                            color: 'white',
                            padding: '6px 12px',
                            '&:hover': {
                                background: '#2C3B80',
                            },
                        }}
                    >
                        Proceed
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog for Toggling API Key Status */}
            <Dialog open={openToggleDialog} onClose={() => setOpenToggleDialog(false)}
                maxWidth="sm"
                fullWidth={true}
                sx={{ "& .MuiDialog-paper": { maxWidth: "400px" } }}
            >
                <DialogTitle>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography sx={{ fontWeight: '600', fontSize: '1.1rem', color: '#333333' }}>
                            {data.disabled ? "Enable API Key" : "Disable API Key"}
                        </Typography>

                        <IconButton onClick={() => setOpenToggleDialog(false)} size="small">
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <hr />
                <DialogContent>
                    <Typography sx={{ fontSize: '0.9rem', fontWeight: '500', color: '#333333' }}>
                        {data.disabled
                            ? "Are you sure you want to enable this API key? This will allow API access."
                            : "Are you sure you want to disable this API key? Any integrations using this key will stop working immediately."}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{ color: '#374DA0', fontWeight: '600', fontSize: '1rem' }}
                        onClick={() => setOpenToggleDialog(false)}
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={DisableKey}
                        sx={{
                            backgroundColor: data.disabled ? '#374DA0' : '#ff1717',
                            fontWeight: 600,
                            fontSize: '1rem',
                            color: 'white',
                            padding: '6px 12px',
                            '&:hover': {
                                backgroundColor: '#2C3B80',
                            },
                        }}
                    >
                        {data.disabled ? 'Confirm' : ' Yes, Disable Key'}
                    </Button>

                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default Api;